import React, { useEffect } from "react";
import { Block, Module, Section, Activity } from "./";

export enum ModalType {
    Block = "block",
    Module = "module",
    Section = "section",
    Activity = "activity",
}

interface Props {
    type?: ModalType;
    partOf: string;
    closeModal?: () => void;
    courseId?: string;
    data?: any;
    blockType: null;
}

interface ActivityBlockProps {
    type?: ModalType;
    partOf: string;
    closeModal?: () => void;
    courseId?: string;
    data?: any;
    blockOf: "how" | "why";
    blockType: "activityBlock";
}

interface SectionBlockProps {
    type?: ModalType;
    partOf: string;
    closeModal?: () => void;
    courseId?: string;
    data?: any;
    blockType: "section";
}

export default function Modal(
    props: Props | ActivityBlockProps | SectionBlockProps,
) {
    const { type, closeModal, courseId, partOf, data, blockType } = props;
    useEffect(() => {
        const checkIfClickedOutside = (event: MouseEvent) => {
            const target = event.target as HTMLElement;
            if (target.closest(".modal")) return;
            closeModal && closeModal();
        };
        document.addEventListener("mousedown", checkIfClickedOutside);
        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, []);

    let modalContent: JSX.Element | null = null;

    switch (type) {
        case ModalType.Block:
            if (blockType === "section") {
                modalContent = (
                    <Block
                        closeModal={closeModal}
                        courseId={courseId}
                        partOf={partOf}
                        data={data}
                        blockType={blockType}
                    />
                );
            }

            if (blockType === "activityBlock") {
                modalContent = (
                    <Block
                        closeModal={closeModal}
                        courseId={courseId}
                        partOf={partOf}
                        data={data}
                        blockType={blockType}
                        blockOf={props.blockOf}
                    />
                );
            }

            break;
        case ModalType.Module:
            modalContent = (
                <Module
                    closeModal={closeModal}
                    courseId={courseId}
                    data={data}
                />
            );
            break;
        case ModalType.Section:
            modalContent = (
                <Section
                    closeModal={closeModal}
                    courseId={courseId}
                    partOf={partOf}
                    data={data}
                />
            );
            break;
        case ModalType.Activity:
            modalContent = (
                <Activity
                    closeModal={closeModal}
                    courseId={courseId}
                    partOf={partOf}
                    data={data}
                />
            );
            break;
        default:
            modalContent = null;
    }
    return (
        <div
            style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 100,
                overflow: "hidden",
            }}
        >
            <div
                style={{
                    maxWidth: 800,
                    width: "80%",
                    height: "80%",
                    backgroundColor: "white",
                    overflow: "hidden",
                    overflowY: "scroll",
                    paddingBottom: 24,
                }}
                className="modal"
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        padding: 12,
                    }}
                >
                    <button
                        style={{
                            backgroundColor: "transparent",
                            border: "none",
                            fontSize: 18,
                            cursor: "pointer",
                        }}
                        onClick={closeModal}
                    >
                        X
                    </button>
                </div>
                {modalContent}
            </div>
        </div>
    );
}

import React from "react";
import "./loader.css";

interface Props {
    text: string;
}

export default function Loader({ text }: Props) {
    return (
        <div
            style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                padding: 20,
                borderRadius: 10,
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
                color: "white",
            }}
        >
            <div className="loader" />
            <div>{text}</div>
        </div>
    );
}

import axios, { AxiosProgressEvent } from "axios";
export const awsService = axios.create();

export interface Progress {
    total: number;
    loaded: number;
}

export const uploadFile = async (
    file: string,
    presignedUrl: string,
    progress: ({ total, loaded }: Progress) => void,
) => {
    const config = {
        onUploadProgress: (progressEvent: AxiosProgressEvent) => {
            const { total = 0, loaded } = progressEvent;
            progress({ total, loaded });
        },
    };
    return awsService.put(presignedUrl, file, config);
};

import React from "react";
import { ApiCourseBlockSchema } from "../../types/apiTypes";

interface Props {
    block: ApiCourseBlockSchema;
}

export default function Image({ block }: Props) {
    return (
        <div>
            {block.title ? <h4>{block.title}</h4> : <div />}
            <img src={block.imagePath} style={{ width: "100%", height: 300 }} />
            {block.altText ? (
                <div
                    style={{
                        fontSize: 12,
                        color: "grey",
                        marginTop: 5,
                        fontStyle: "italic",
                    }}
                >
                    {block.altText}
                </div>
            ) : (
                <div />
            )}
        </div>
    );
}

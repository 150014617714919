import jwt_decode from "jwt-decode";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  login,
  register,
  refreshTokens,
  setApiToken,
} from "../../service/ecuApi/api";
import { getToken, setToken } from "../../service/token";

export type SupportedLanguages = "cs" | "en" | "es" | "fr" | "hr" | "sv";

interface LoginData {
  email: string;
  password: string;
}

export const fetchLogin = createAsyncThunk(
  "user/fetchLogin",
  async (queryParams: LoginData, thunkAPI) => {
    const loginResponse = await login(queryParams);
    if (loginResponse.status !== 200) {
      throw new Error("Login failed");
    }
    await setToken(loginResponse.data.token, loginResponse.data.refreshToken);
    setApiToken(loginResponse.data.token);
    return loginResponse.data;
  }
);

interface DecodedToken {
  _id: string;
  iat: number;
  exp: number;
  email: string;
  name: string;
}

interface RestoredUser extends DecodedToken {
  token: string;
  refreshToken: string;
}

export const restoreUser = createAsyncThunk(
  "user/restoreUser",
  async (
    queryParams: { token: string; refreshToken: string },
    thunkAPI
  ): Promise<RestoredUser> => {
    const decoded = jwt_decode(queryParams.token) as DecodedToken;
    if (decoded.exp * 1000 < Date.now()) {
      throw new Error("Token expired");
    }
    return {
      ...decoded,
      token: queryParams.token,
      refreshToken: queryParams.refreshToken,
    };
  }
);

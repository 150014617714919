import "./App.css";
import EcuRouter from "./router/Router";
import { Provider } from "react-redux";
import { store } from "./store/";

function App() {
    return (
        <Provider store={store}>
            <EcuRouter />
        </Provider>
    );
}

export default App;

import React from "react";
import { ApiCourseBlockSchema } from "../../types/apiTypes";

interface Props {
    block: ApiCourseBlockSchema;
    showTitle: boolean;
    isLast?: boolean;
}

export default function Glossary({ block, showTitle, isLast }: Props) {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                backgroundColor: "pink",
                marginBottom: isLast ? 20 : 0,
            }}
        >
            <div style={{ width: 10, backgroundColor: "red" }} />
            <div style={{ padding: 12, width: 700 }}>
                {showTitle && block.title ? <h4>Glossary</h4> : <div />}
                {block.title ? <h5>{block.title}</h5> : <div />}
                <p
                    style={{
                        whiteSpace: "pre-wrap",
                    }}
                >
                    {block.content}
                </p>
            </div>
        </div>
    );
}

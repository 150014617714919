export const setToken = async (token: string, refreshToken: string) => {
    await localStorage.setItem("token", token);
    await localStorage.setItem("refreshToken", refreshToken);
    return { token, refreshToken };
};

export const getToken = () => {
    const token = localStorage.getItem("token");
    const refreshToken = localStorage.getItem("refreshToken");
    if (token && refreshToken) {
        return { token, refreshToken };
    } else {
        return undefined;
    }
};

export const removeToken = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    return undefined;
};

import React, { useEffect, useState } from "react";
import CourseSummaryViewer from "../components/courseSummaryViewer/CourseSummaryViewer";
import { useAppDispatch, useAppSelector } from "../hooks/reduxHooks";
import { fetchCourses } from "../store/courses/coursesThunk";

interface Props {}

export default function Root({}: Props) {
  const dispatch = useAppDispatch();
  const { language } = useAppSelector((state) => state.settings);
  useEffect(() => {
    setTimeout(() => {
      dispatch(fetchCourses(language));
    }, 1000);
  }, [dispatch]);
  const { courses } = useAppSelector((state) => state.courses);

  if (!courses.length) return <div>Loading...</div>;

  return (
    <div>
      {courses.map((course) => {
        return (
          <div key={course._id}>
            <CourseSummaryViewer course={course} />
          </div>
        );
      })}
    </div>
  );
}

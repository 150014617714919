import { createSlice } from "@reduxjs/toolkit";
import { SupportedLanguages } from "../user/userThunk";

export interface Settings {
  language: SupportedLanguages;
}

// Define a type for the slice state
interface SettingsState extends Settings {
  loading: boolean;
  isLoaded: boolean;
  error: string | undefined;
}

// Define the initial state using that type
const initialState: SettingsState = {
  language: "en",
  loading: false,
  isLoaded: false,
  error: undefined,
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
  },
});

export default settingsSlice.reducer;

import React from "react";
import { ApiCourseBlockSchema } from "../../types/apiTypes";

interface Props {
    block: ApiCourseBlockSchema;
}

export default function Paragraph({ block }: Props) {
    return (
        <div>
            {block.title ? <h4>{block.title}</h4> : <div />}
            <p
                style={{
                    whiteSpace: "pre-wrap",
                }}
            >
                {block.content}
            </p>
        </div>
    );
}

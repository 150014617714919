import React, { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { fetchFullCourseById } from "../../store/course/courseThunk";
import {
  createCourseBlock,
  updateCourseBlock,
  createActivityBlock,
  updateActivityBlock,
  getuploadSlot,
} from "../../service/ecuApi/api";
import { AxiosResponse } from "axios";
import Loader from "../loader/Loader";
import { uploadFile, Progress } from "../../service/aws";

interface ActivityBlockProps {
  blockType: "activityBlock";
  closeModal?: () => void;
  courseId?: string;
  partOf: string;
  data?: any;
  blockOf: "how" | "why";
}

interface CourseBlockProps {
  blockType: "section";
  closeModal?: () => void;
  courseId?: string;
  partOf: string;
  data?: any;
}

type Props = ActivityBlockProps | CourseBlockProps;

export default function Block(props: Props) {
  const { closeModal, partOf, data } = props;
  const [title, setTitle] = useState<string>("");
  const [contentType, setContentType] = useState<string>("text");
  const [content, setContent] = useState<string>("");
  const [imagePath, setImagePath] = useState<string>("");
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);
  const [altText, setAltText] = useState<string>("");
  const [listItems, setListItems] = useState<string[]>([]);
  const [newListItem, setNewListItem] = useState<string>("");
  const [keepOpen, setKeepOpen] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingText, setLoadingText] = useState<string>("");
  const [collapsable, setCollapsable] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const { language } = useAppSelector((state) => state.settings);

  const afterAddBlock = async (query: AxiosResponse<any, any>) => {
    if (query.status !== 200) {
      alert("Något gick fel, försök igen senare.");
      return;
    } else {
      setLoadingText("laddar om data...");
      if (props.courseId) {
        await dispatch(
          fetchFullCourseById({
            id: props.courseId,
            lang: language,
          })
        );
      }
      if (closeModal) {
        if (!keepOpen) {
          closeModal();
        } else {
          setTitle("");
          setContent("");
          setImagePath("");
          setAltText("");
          setListItems([]);
          setNewListItem("");
          setContentType(contentType);
          setCollapsable(false);
        }
        setLoading(false);
        setLoadingText("");
      }
    }
  };

  const onAddBlock = async () => {
    setLoading(true);
    setLoadingText("Lägger till block...");
    let query: AxiosResponse<any, any>;
    const queryData = {
      title,
      contentType,
      content,
      imagePath,
      width,
      height,
      altText,
      listItems,
      collapsable,
    };
    if (props.blockType === "section") {
      if (props.courseId) {
        if (data && data._id) {
          query = await updateCourseBlock({
            id: data._id,
            sectionId: partOf,
            ...queryData,
          });
        } else {
          query = await createCourseBlock({
            sectionId: partOf,
            lang: language,
            ...queryData,
          });
        }
        afterAddBlock(query);
      }
    } else if (props.blockType === "activityBlock") {
      if (props.courseId) {
        if (data && data._id) {
          query = await updateActivityBlock({
            id: data._id,
            activityId: partOf,
            field: props.blockOf,
            ...queryData,
          });
        } else {
          query = await createActivityBlock({
            activityId: partOf,
            field: props.blockOf,
            lang: language,
            ...queryData,
          });
        }
        afterAddBlock(query);
      }
    }
  };

  const onSelectImage = async (e: any) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    setLoading(true);
    setLoadingText("Laddar upp bild...");
    const contentType = file.type;
    const fileName = file.name;
    const slot = await getuploadSlot(contentType, fileName);
    const { url, key } = slot.data;
    console.log({ slot });
    const progress = (p: Progress) => {
      const result = (p.loaded / p.total) * 100;
      setLoadingText(`Laddar upp bild... ${result.toFixed(0)}%`);
    };
    if (slot.status === 200) {
      await uploadFile(file, url, progress)
        .then((r) => {
          setImagePath(
            `https://edchild-uploads.s3.eu-north-1.amazonaws.com/${key}`
          );
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
        });
    }

    setLoading(false);
  };

  useEffect(() => {
    if (data) {
      setTitle(data.title);
      setContentType(data.contentType);
      setContent(data.content);
      setImagePath(data.imagePath);
      setWidth(data.width);
      setHeight(data.height);
      setAltText(data.altText);
      setListItems(data.listItems);
      setCollapsable(data.collapsable);
      setKeepOpen(0);
    }
  }, [data]);

  return (
    <div
      style={{
        marginTop: 12,
        marginLeft: 12,
        marginRight: 12,
      }}
    >
      {loading && <Loader text={loadingText} />}
      <h1>Lägg till ny block</h1>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <label>Typ</label>
        <select
          onChange={(e) => {
            setContentType(e.target.value);
          }}
          style={{ marginBottom: 12, height: 30 }}
        >
          <option value="text" selected={contentType === "text"}>
            Paragraf
          </option>
          '
          <option value="image" selected={contentType === "image"}>
            Bild
          </option>
          '
          <option value="glossary" selected={contentType === "glossary"}>
            Gloslista
          </option>
          <option
            value="numberedList"
            selected={contentType === "numberedList"}
          >
            Numrerad lista
          </option>
          <option value="bulletList" selected={contentType === "bulletList"}>
            Punktlista
          </option>
        </select>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <label>Titel</label>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          style={{ marginBottom: 12, height: 30 }}
        />
      </div>
      {contentType === "text" || contentType === "glossary" ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          {props.blockType === "activityBlock" ? (
            <div>
              <label>Collapsable</label>
              <input
                type="checkbox"
                checked={collapsable}
                onChange={() => setCollapsable((prev) => !prev)}
              />
            </div>
          ) : null}
          <label>Beskrivning *</label>
          <textarea
            value={content}
            onChange={(e) => setContent(e.target.value)}
            style={{ marginBottom: 12, minHeight: 100 }}
          />
        </div>
      ) : null}
      {contentType === "image" ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <label>Bild URL *</label>
          <input type="file" name="file" onChange={onSelectImage} />
          {imagePath && (
            <img
              src={imagePath}
              style={{
                width: "100%",
                height: "auto",
                paddingTop: 12,
                paddingBottom: 12,
              }}
            />
          )}
          <label>Caption</label>
          <input
            type="text"
            value={altText}
            onChange={(e) => setAltText(e.target.value)}
            style={{ marginBottom: 12, height: 30 }}
          />
        </div>
      ) : null}
      {contentType.includes("List") ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <label>Lista *</label>
          <input
            type="text"
            value={newListItem}
            onChange={(e) => setNewListItem(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setListItems([...listItems, newListItem]);
                setNewListItem("");
              }
            }}
            style={{ marginBottom: 12, height: 30 }}
          />
          {contentType === "numberedList" ? (
            <ol>
              {listItems.map((item, index) => (
                <li key={`${index}-newListItems`}>
                  {item}{" "}
                  <button
                    onClick={() => {
                      const newList = listItems.filter((_, i) => i !== index);
                      setListItems(newList);
                    }}
                    style={{
                      color: "red",
                      backgroundColor: "transparent",
                      border: "none",
                      cursor: "pointer",
                    }}
                  >
                    X
                  </button>
                </li>
              ))}
            </ol>
          ) : (
            <ul>
              {listItems.map((item, index) => (
                <li key={`${index}-newListItems`}>
                  {item}{" "}
                  <button
                    onClick={() => {
                      const newList = listItems.filter((_, i) => i !== index);
                      setListItems(newList);
                    }}
                    style={{
                      color: "red",
                      backgroundColor: "transparent",
                      border: "none",
                      cursor: "pointer",
                    }}
                  >
                    X
                  </button>
                </li>
              ))}
            </ul>
          )}
        </div>
      ) : null}
      <div
        style={{
          marginBottom: 12,
        }}
      >
        <input
          type="checkbox"
          id="keepopen"
          name="keepopen"
          value={keepOpen}
          onChange={(e) => setKeepOpen(e.target.checked ? 1 : 0)}
          disabled={data && data._id}
        />
        <label htmlFor="keepopen"> Keep open</label>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <button
          onClick={() => {
            if (closeModal) {
              closeModal();
            }
          }}
        >
          Avbryt
        </button>
        <button
          onClick={async () => {
            onAddBlock();
          }}
        >
          Spara
        </button>
      </div>
    </div>
  );
}

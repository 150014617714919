import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../hooks/reduxHooks";
import { fetchLogin } from "../store/user/userThunk";

interface Props {}

export default function Login({}: Props) {
    const dispatch = useAppDispatch();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const error = useAppSelector((state) => state.user.error);

    const onDoLogin = () => {
        dispatch(fetchLogin({ email, password }));
    };

    const inputStyle = {
        marginBottom: 12,
        height: 30,
        width: 200,
        padding: 4,
        fontSize: 16,
        borderRadius: 4,
        border: "1px solid #ccc",
    };

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
                width: "100vw",
            }}
        >
            <h1>Login</h1>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <input
                    type="text"
                    placeholder="email"
                    style={inputStyle}
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                />
                <input
                    type="password"
                    placeholder="password"
                    style={inputStyle}
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                />
                <button
                    style={{
                        marginBottom: 12,
                        height: 40,
                        width: 210,
                        fontSize: 16,
                        borderRadius: 4,
                        border: "1px solid #ccc",
                        cursor: "pointer",
                    }}
                    onClick={() => onDoLogin()}
                >
                    Login
                </button>
                {error && (
                    <div style={{ color: "red" }}>Fel email eller lösenord</div>
                )}
            </div>
        </div>
    );
}

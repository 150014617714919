import React from "react";
import { Course } from "../../types/apiTypes";
import { Link } from "react-router-dom";

interface Props {
    course: Course;
}

export default function CourseSummaryViewer({ course }: Props) {
    return (
        <>
            <h1>{course.name}</h1>
            <p>{course.description}</p>
            <div>
                <Link to={`/course/${course._id}`}>view</Link> | edit | delete
            </div>
        </>
    );
}

import { createSlice } from "@reduxjs/toolkit";
import { fetchLogin, restoreUser } from "./userThunk";

interface User {
    id: string;
    email: string;
    name: string;
    token: string;
    refreshToken: string;
}

// Define a type for the slice state
interface UserState {
    user: User | undefined;
    isAuthenticated: boolean;
    error: boolean;
}

// Define the initial state using that type
const initialState: UserState = {
    user: undefined,
    isAuthenticated: false,
    error: false,
};

export const userSlice = createSlice({
    name: "user",
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        reset: () => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchLogin.fulfilled, (state, action) => {
            state.user = action.payload;
            state.isAuthenticated = true;
            state.error = false;
        });
        builder.addCase(fetchLogin.rejected, (state) => {
            state.user = undefined;
            state.isAuthenticated = false;
            state.error = true;
        });
        builder.addCase(restoreUser.fulfilled, (state, action) => {
            state.user = { ...action.payload, id: action.payload._id };
            state.isAuthenticated = true;
            state.error = false;
        });
    },
});

export default userSlice.reducer;

import React, { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { fetchFullCourseById } from "../../store/course/courseThunk";
import {
  createCourseModule,
  updateCourseModule,
  getuploadSlot,
} from "../../service/ecuApi/api";
import { AxiosResponse } from "axios";
import { uploadFile, Progress } from "../../service/aws";
import Loader from "../loader/Loader";

interface Props {
  closeModal?: () => void;
  courseId?: string;
  data?: any;
}

export default function Module({ closeModal, courseId, data }: Props) {
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [goal, setGoal] = useState<string>("");
  const [color, setColor] = useState<string>("#000000");
  const [coverImagePath, setCoverImagePath] = useState<string>("");
  const [loadingText, setLoadingText] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const { language } = useAppSelector((state) => state.settings);

  useEffect(() => {
    if (data) {
      setName(data.name);
      setDescription(data.description);
      setGoal(data.goal);
      setColor(data.color);
      setCoverImagePath(data.coverImagePath);
    }
  }, [data]);

  const onSelectImage = async (e: any) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    setLoading(true);
    setLoadingText("Laddar upp bild...");
    const contentType = file.type;
    const fileName = file.name;
    const slot = await getuploadSlot(contentType, fileName);
    const { url, key } = slot.data;
    console.log({ slot });
    const progress = (p: Progress) => {
      const result = (p.loaded / p.total) * 100;
      setLoadingText(`Laddar upp bild... ${result.toFixed(0)}%`);
    };
    if (slot.status === 200) {
      await uploadFile(file, url, progress)
        .then((r) => {
          setCoverImagePath(
            `https://edchild-uploads.s3.eu-north-1.amazonaws.com/${key}`
          );
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
        });
    }

    setLoading(false);
  };

  return (
    <div
      style={{
        marginTop: 12,
        marginLeft: 12,
        marginRight: 12,
      }}
    >
      {loading && <Loader text={loadingText} />}
      <h1>Lägg till ny modul</h1>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <label>Titel</label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          style={{ marginBottom: 12, height: 30 }}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <label>Bild sökväg</label>
        <input
          type="text"
          value={coverImagePath}
          onChange={(e) => setCoverImagePath(e.target.value)}
          style={{ marginBottom: 12, height: 30 }}
        />
      </div>
      <input type="file" name="file" onChange={onSelectImage} />
      {coverImagePath ? (
        <img
          src={coverImagePath}
          style={{ width: "100%", paddingTop: 12, paddingBottom: 12 }}
        />
      ) : (
        <div />
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <label>Color (hex value i.e. #000000)</label>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <input
            type="text"
            value={color}
            onChange={(e) => setColor(e.target.value)}
            style={{ marginBottom: 12, height: 30 }}
          />
          <div
            style={{
              marginBottom: 12,
              width: 36,
              height: 36,
              backgroundColor: color,
              borderRadius: 4,
              marginLeft: 12,
            }}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <label>Beskrivning</label>
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          style={{ marginBottom: 12, minHeight: 100 }}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <label>Mål</label>
        <textarea
          value={goal}
          onChange={(e) => setGoal(e.target.value)}
          style={{ marginBottom: 12, height: 100 }}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <button
          onClick={() => {
            if (closeModal) {
              closeModal();
            }
          }}
        >
          Avbryt
        </button>
        <button
          onClick={async () => {
            if (courseId) {
              setLoading(true);
              setLoadingText("Uppdaterar modulen...");
              let course: AxiosResponse<any, any>;
              if (data && data._id) {
                course = await updateCourseModule({
                  name,
                  description,
                  goal,
                  coverImagePath,
                  courseId,
                  color,
                  id: data._id,
                  lang: language,
                });
              } else {
                setLoading(true);
                setLoadingText("Sparar modulen...");
                course = await createCourseModule({
                  name,
                  description,
                  goal,
                  coverImagePath,
                  courseId,
                  color,
                });
              }

              if (course.status !== 200) {
                setLoading(false);
                console.log(course);
                return;
              } else {
                if (courseId) {
                  setLoadingText("Uppaterar data...");
                  await dispatch(
                    fetchFullCourseById({
                      id: courseId,
                      lang: language,
                    })
                  );
                  setLoading(false);
                }
                if (closeModal) {
                  closeModal();
                }
              }
            }
          }}
        >
          Spara
        </button>
      </div>
    </div>
  );
}

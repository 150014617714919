import React from "react";
import { ApiCourseBlockSchema } from "../../types/apiTypes";

interface Props {
    block: ApiCourseBlockSchema;
}

export default function NumberedList({ block }: Props) {
    const listItems = block.listItems;
    return (
        <div>
            {block.title ? <h4>{block.title}</h4> : <div />}
            <ol>
                {listItems?.map((item, index) => (
                    <li key={`numberedList${block._id}_${index}`}>{item}</li>
                ))}
            </ol>
        </div>
    );
}

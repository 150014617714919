import React, { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { AxiosResponse } from "axios";
import {
  updateActivity,
  createActivity,
  getuploadSlot,
} from "../../service/ecuApi/api";
import { fetchFullCourseById } from "../../store/course/courseThunk";
import { uploadFile, Progress } from "../../service/aws";

interface Props {
  closeModal?: () => void;
  courseId?: string;
  partOf?: string;
  data?: any;
}

export default function Activity({
  closeModal,
  courseId,
  partOf,
  data,
}: Props) {
  const [name, setName] = useState<string>("");
  const [coverImagePath, setCoverImagePath] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingText, setLoadingText] = useState<string>("");

  const { language } = useAppSelector((state) => state.settings);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (data) {
      setName(data.name);
      setCoverImagePath(data.coverImagePath);
    }
  }, [data]);

  const onSelectImage = async (e: any) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    setLoading(true);
    setLoadingText("Laddar upp bild...");
    const contentType = file.type;
    const fileName = file.name;
    const slot = await getuploadSlot(contentType, fileName);
    const { url, key } = slot.data;
    console.log({ slot });
    const progress = (p: Progress) => {
      const result = (p.loaded / p.total) * 100;
      setLoadingText(`Laddar upp bild... ${result.toFixed(0)}%`);
    };
    if (slot.status === 200) {
      await uploadFile(file, url, progress)
        .then((r) => {
          setCoverImagePath(
            `https://edchild-uploads.s3.eu-north-1.amazonaws.com/${key}`
          );
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
        });
    }

    setLoading(false);
  };

  return (
    <div
      style={{
        marginTop: 12,
        marginLeft: 12,
        marginRight: 12,
      }}
    >
      <h1>{data ? "Regigera" : "Lägg till ny"} aktivitet</h1>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <label>Titel</label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          style={{ marginBottom: 12, height: 30 }}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          marginBottom: 12,
        }}
      >
        <label>Bild URL *</label>
        <input type="file" name="file" onChange={onSelectImage} />
        {coverImagePath && (
          <img
            src={coverImagePath}
            style={{
              width: "100%",
              height: "auto",
              paddingTop: 12,
              paddingBottom: 12,
            }}
          />
        )}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <button
          onClick={() => {
            if (closeModal) {
              closeModal();
            }
          }}
        >
          Avbryt
        </button>
        <button
          onClick={async () => {
            if (courseId && partOf) {
              let query: AxiosResponse<any, any>;
              if (data && data._id) {
                query = await updateActivity({
                  id: data._id,
                  name,
                  coverImagePath,
                  courseModuleId: partOf,
                  lang: language,
                });
              } else {
                query = await createActivity({
                  name,
                  courseModuleId: partOf,
                  coverImagePath,
                });
              }
              if (query.status !== 200) {
                return;
              } else {
                if (courseId) {
                  dispatch(
                    fetchFullCourseById({
                      id: courseId,
                      lang: language,
                    })
                  );
                }
                if (closeModal) {
                  closeModal();
                }
              }
            }
          }}
        >
          Spara
        </button>
      </div>
    </div>
  );
}

import React from "react";
import { ApiCourseBlockSchema } from "../../types/apiTypes";
import Paragraph from "./Paragraph";
import Image from "./Image";
import Glossary from "./Glossary";
import NumberedList from "./NumberedList";
import BulletList from "./BulletList";

interface Props {
    block: ApiCourseBlockSchema;
    prevBlock: ApiCourseBlockSchema | null;
    nextBlock: ApiCourseBlockSchema | null;
}

export default function Block({ block, prevBlock, nextBlock }: Props) {
    const component: JSX.Element = <div />;

    switch (block.contentType) {
        case "text":
            return <Paragraph block={block} />;
        case "image":
            return <Image block={block} />;
        case "glossary":
            return (
                <Glossary
                    block={block}
                    showTitle={prevBlock?.contentType !== "glossary"}
                    isLast={nextBlock?.contentType !== "glossary"}
                />
            );
        case "numberedList":
            return <NumberedList block={block} />;
        case "bulletList":
            return <BulletList block={block} />;
        default:
            return <div />;
    }
}

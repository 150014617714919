import React from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";

const LanguagePicker = () => {
  const dispatch = useAppDispatch();
  const { language } = useAppSelector((state) => state.settings);

  const handleLanguageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch({ type: "settings/setLanguage", payload: e.target.value });
  };
  return (
    <div style={{ marginTop: 8 }}>
      <label htmlFor="language">Language</label>
      <select onChange={handleLanguageChange} value={language}>
        <option value="cs">Czeck</option>
        <option value="en">English</option>
        <option value="es">Spanish</option>
        <option value="fr">French</option>
        <option value="hr">Croatian</option>
        <option value="sv">Swedish</option>
      </select>
    </div>
  );
};

export default LanguagePicker;

import { createSlice } from "@reduxjs/toolkit";
import { Course } from "../../types/apiTypes";
import { fetchCourses } from "./coursesThunk";

// Define a type for the slice state
interface CourseState {
    courses: Course[];
}

// Define the initial state using that type
const initialState: CourseState = {
    courses: [],
};

export const coursesSlice = createSlice({
    name: "courses",
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        reset: () => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCourses.fulfilled, (state, action) => {
            state.courses = action.payload;
        });
    },
});

export default coursesSlice.reducer;

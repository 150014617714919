import { createSlice } from "@reduxjs/toolkit";
import { Course } from "../../types/apiTypes";
import { fetchFullCourseById } from "./courseThunk";

// Define a type for the slice state
interface CourseState {
  course: Course | undefined;
  loading: boolean;
}

// Define the initial state using that type
const initialState: CourseState = {
  course: undefined,
  loading: false,
};

export const courseSlice = createSlice({
  name: "course",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFullCourseById.fulfilled, (state, action) => {
      state.course = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchFullCourseById.pending, (state, action) => {
      state.loading = true;
    });
  },
});

export default courseSlice.reducer;

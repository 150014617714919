import React, { useState, useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Root, Login, Course } from "../pages";
import { useAppSelector, useAppDispatch } from "../hooks/reduxHooks";
import { getToken } from "../service/token";
import { setApiToken } from "../service/ecuApi/api";
import { restoreUser } from "../store/user/userThunk";

const privateRouter = createBrowserRouter([
    {
        path: "/",
        element: <Root />,
    },
    {
        path: "/course/:courseId",
        element: <Course />,
    },
]);

const publicRouter = createBrowserRouter([
    {
        path: "/",
        element: <Login />,
    },
]);

const EcuRouter = () => {
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const isLogin = useAppSelector((state) => state.user.isAuthenticated);

    useEffect(() => {
        const token = getToken();
        if (token) {
            setApiToken(token.token);
            dispatch(restoreUser(token));
            setIsLoading(false);
        } else {
            setIsLoading(false);
        }
    }, [isLoading]);

    if (isLoading) {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                    fontSize: "3rem",
                }}
            >
                Laddar...
            </div>
        );
    }

    return isLogin ? (
        <RouterProvider router={privateRouter} />
    ) : (
        <RouterProvider router={publicRouter} />
    );
};

export default EcuRouter;

import axios from "axios";
import { SupportedLanguages } from "../../store/user/userThunk";

// const sleep = (delay: number) => {
//     return new Promise(function (resolve) {
//         setTimeout(resolve, delay);
//     });
// };

const api = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "https://ece-api.captain.brochard.se"
      : "https://ece-api.captain.brochard.se",
  timeout: 1000,
});

// api.interceptors.response.use(
//     async function (response) {
//         await sleep(500);
//         return response;
//     },
//     function (error) {
//         // Do something with response error
//         console.error(error);
//         return Promise.reject(error);
//     },
// );

export const setApiToken = (token: string) => {
  api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

export const login = (data: { email: string; password: string }) =>
  api.post(`/user/login`, data);

export const register = (data: {
  email: string;
  password: string;
  name: string;
}) => api.post(`/user`, data);

export const refreshTokens = (refreshToken: string) =>
  api.get(`/user/refresh-tokens`, {
    headers: {
      Authorization: `Bearer ${refreshToken}`,
    },
  });

export const getCourses = async (lang: SupportedLanguages) =>
  api.get(`/course/${lang}`);

export const getCourse = (id: string, lang: SupportedLanguages) =>
  api.get(`/course/${id}/${lang}`);

export const getCourseModules = async (
  filters: any,
  lang: SupportedLanguages
) => api.get(`courseModule/${lang}?filters=${JSON.stringify(filters)}`);

export const getCourseSections = async (
  filters: any,
  lang: SupportedLanguages
) => api.get(`courseSection/${lang}?filters=${JSON.stringify(filters)}`);

export const getCourseBlocks = async (filters: any, lang: SupportedLanguages) =>
  api.get(`courseBlock?filters=${JSON.stringify(filters)}`);

export const getActivities = async (filters: any, lang: SupportedLanguages) =>
  api.get(`activity/${lang}?filters=${JSON.stringify(filters)}`);

export const getActivityBlocks = async (
  filters: any,
  lang: SupportedLanguages
) => api.get(`activityBlock?filters=${JSON.stringify(filters)}`);

export const createCourseModule = (data: {
  name: string;
  description: string;
  goal: string;
  coverImagePath: string;
  courseId: string;
  color: string;
}) => api.post(`/courseModule`, data);

export const updateCourseModule = (data: {
  id: string;
  name: string;
  description: string;
  goal: string;
  coverImagePath: string;
  courseId: string;
  color: string;
  lang: SupportedLanguages;
}) => api.put(`/courseModule/${data.id}/${data.lang}`, data);

export const createCourseSection = (data: { name: string; moduleId: string }) =>
  api.post(`/courseSection`, data);

export const updateCourseSection = (data: {
  id: string;
  name: string;
  moduleId: string;
  lang: SupportedLanguages;
}) => api.put(`/courseSection/${data.id}/${data.lang}`, data);

export const createCourseBlock = (data: {
  contentType: string;
  title: string;
  content: string;
  imagePath: string;
  altText: string;
  width: number;
  height: number;
  listItems: string[];
  sectionId: string;
  lang: SupportedLanguages;
}) => api.post(`/courseBlock`, data);

export const updateCourseBlock = (data: {
  id: string;
  contentType: string;
  title: string;
  content: string;
  imagePath: string;
  altText: string;
  width: number;
  height: number;
  listItems: string[];
  sectionId: string;
}) => api.put(`/courseBlock/${data.id}`, data);

export const createActivity = (data: {
  name: string;
  coverImagePath: string;
  active?: boolean;
  courseModuleId: string;
}) => api.post(`/activity`, data);

export const updateActivity = (data: {
  id: string;
  name: string;
  coverImagePath: string;
  active?: boolean;
  courseModuleId: string;
  lang: SupportedLanguages;
}) => api.put(`/activity/${data.id}/${data.lang}`, data);

export const createActivityBlock = (data: {
  contentType: string;
  title: string;
  content: string;
  imagePath: string;
  altText: string;
  width: number;
  height: number;
  listItems: string[];
  activityId: string;
  field: string;
  lang: SupportedLanguages;
}) => api.post(`/activityBlock`, data);

export const updateActivityBlock = (data: {
  id: string;
  contentType: string;
  title: string;
  content: string;
  imagePath: string;
  altText: string;
  width: number;
  height: number;
  listItems: string[];
  activityId: string;
  field: string;
}) => api.put(`/activityBlock/${data.id}`, data);

export const deleteBlock = async (blockId: string, lang: SupportedLanguages) =>
  api.delete(`/courseblock/${blockId}/${lang}`);

export const deleteCourseBlock = (id: string) =>
  api.delete(`/courseblock/${id}`);

export const deleteActivity = (id: string) => api.delete(`/activity/${id}`);

export const deleteActivityBlock = (id: string, lang: SupportedLanguages) =>
  api.delete(`/activityBlock/${id}/${lang}`);

export const getModule = (id: string, lang: SupportedLanguages) =>
  api.get(`/coursemodule/${id}/${lang}`);

export const getSection = (id: string, lang: SupportedLanguages) =>
  api.get(`/coursesection/${id}/${lang}`);

export const getBlock = (id: string, lang: SupportedLanguages) =>
  api.get(`/courseblock/${id}`);

export const getuploadSlot = (contentType: string, fileName: string) =>
  api.post("/storage/getPresignedUrl/courses/image", {
    contentType,
    fileName,
  });

export const getPresignedUrl = (key: string) =>
  api.get("/storage/getCoursePresignedUrl", { params: { key } });

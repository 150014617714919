import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hooks/reduxHooks";
import { fetchFullCourseById } from "../store/course/courseThunk";
import Block from "../components/blocs/Block";
import Modal, { ModalType } from "../components/modals/Modal";
import { deleteActivityBlock, deleteBlock } from "../service/ecuApi/api";
import LanguagePicker from "../components/languagePicker/LanguagePicker";

export default function Course() {
  const { courseId } = useParams<{ courseId: string }>();
  const [hideStructure, setHideStructure] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState<ModalType>();
  const [modalData, setModalData] = useState<any>(null);
  const [modalBlockType, setModalBlockType] = useState<
    "activity" | "section" | null
  >(null);
  const [partOf, setPartOf] = useState<string>("");
  const { course, loading } = useAppSelector((state) => state.course);
  const [filter, setFilter] = useState<string>("");
  const [blockOf, setBlockOf] = useState<"how" | "why">("how");

  const { language } = useAppSelector((state) => state.settings);

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (courseId) {
      dispatch(fetchFullCourseById({ id: courseId, lang: language }));
    }
  }, [dispatch, courseId, language]);

  const onDeleteBlockPress = async (id: string) => {
    if (window.confirm("Vill du verkligen ta bort detta?")) {
      await deleteBlock(id, language);
      if (courseId) {
        dispatch(fetchFullCourseById({ id: courseId, lang: language }));
      }
    } else {
      alert("You pressed Cancel!");
    }
  };

  const onDeleteActvityBlockPress = async (id: string) => {
    if (window.confirm("Vill du verkligen ta bort detta?")) {
      await deleteActivityBlock(id, language)
        .then((res) => res)
        .catch((err) => console.log(err));
      if (courseId) {
        dispatch(fetchFullCourseById({ id: courseId, lang: language }));
      }
    } else {
      alert("You pressed Cancel!");
    }
  };

  if (!course || !course._id || course._id !== courseId) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          fontSize: "3rem",
        }}
      >
        Laddar...
      </div>
    );
  }

  const filteredModules = course.modules?.filter((module) => {
    if (!filter || hideStructure) {
      return true;
    }
    return module._id === filter;
  });

  const renderModal = () => {
    switch (modalBlockType) {
      case "section":
        return (
          <Modal
            type={modalType}
            closeModal={() => setShowModal(false)}
            partOf={partOf}
            courseId={course._id}
            data={modalData}
            blockType={"section"}
          />
        );

      case "activity":
        return (
          <Modal
            type={modalType}
            closeModal={() => setShowModal(false)}
            partOf={partOf}
            courseId={course._id}
            data={modalData}
            blockType={"activityBlock"}
            blockOf={blockOf}
          />
        );

      default:
        return (
          <Modal
            type={modalType}
            closeModal={() => setShowModal(false)}
            partOf={partOf}
            courseId={course._id}
            data={modalData}
            blockType={modalBlockType}
          />
        );
    }
  };

  return (
    <div
      style={{
        maxWidth: 800,
        marginLeft: 12,
        marginRight: 12,
        marginBottom: 12,
        paddingTop: 12,
      }}
    >
      {loading && (
        <div
          style={{
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            width: "100vw",
            fontSize: "3rem",
            zIndex: 100,
            backgroundColor: "rgba(255,255,255,0.8)",
          }}
        >
          Laddar...
        </div>
      )}
      {showModal ? renderModal() : null}
      <button onClick={() => setHideStructure(!hideStructure)}>
        Växla till {hideStructure ? "redigera" : "läs"}lägge
      </button>
      <LanguagePicker />
      <h1>{course.name}</h1>
      <p>{course.description}</p>
      {hideStructure ? null : (
        <>
          <h3>Filtrera moduler</h3>
          <select
            name="filter"
            id="filter"
            onChange={(e) => setFilter(e.target.value)}
          >
            <option value={""} selected={filter === ""}>
              Visa alla
            </option>
            {course?.modules?.map((module, index) => (
              <option
                value={module._id}
                selected={filter === module._id}
                key={module._id}
              >
                {module.name}
              </option>
            ))}
          </select>
        </>
      )}
      {filteredModules?.map((module, index) => (
        <div
          key={module._id}
          style={
            hideStructure
              ? {
                  marginTop: 24,
                  marginBottom: 24,
                  padding: 12,
                  borderWidth: 3,
                  borderColor: module.color,
                  borderStyle: "solid",
                  borderRadius: 12,
                }
              : {
                  marginTop: 24,
                  marginBottom: 24,
                  padding: 12,
                  borderWidth: 3,
                  borderColor: module.color,
                  borderStyle: "solid",
                  borderRadius: 12,
                }
          }
        >
          {hideStructure ? null : (
            <div
              style={{
                position: "relative",
                top: -28,
                fontSize: 24,
                backgroundColor: "white",
                paddingLeft: 12,
                width: 110,
              }}
            >
              Module {index + 1}
            </div>
          )}
          {module.coverImagePath ? (
            <img
              src={module.coverImagePath}
              style={{ width: "100%", marginTop: -28 }}
            />
          ) : (
            <div />
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h2>
              Module {index + 1} - {module.name}
            </h2>
            {hideStructure ? null : (
              <button
                style={{
                  height: 30,
                  marginLeft: 12,
                  marginRight: 26,

                  cursor: "pointer",
                  border: "none",
                  backgroundColor: "white",
                  color: "blue",
                  marginBottom: 5,
                }}
                onClick={() => {
                  setModalType(ModalType.Module);
                  setPartOf(course._id);
                  setShowModal(true);
                  setModalData(module);
                }}
              >
                Redigera
              </button>
            )}
          </div>
          <strong>Beskrivning</strong>
          <p>{module.description}</p>
          {module.sections?.length && hideStructure ? (
            <div
              style={{
                marginBottom: 12,
              }}
            >
              In Module {index + 1}, we will cover the following Chapters.
              {module?.sections?.map((section, si) => (
                <div key={section._id}>
                  {index + 1}.{si + 1} - {section.name}
                </div>
              ))}
            </div>
          ) : null}
          <strong>Mål</strong>
          <p>{module.goal}</p>
          {module?.sections?.map((section, si) => (
            <div
              key={section._id}
              style={
                hideStructure
                  ? {
                      borderTop: `3px solid ${module.color}`,
                      marginTop: 50,
                      paddingTop: 30,
                    }
                  : {
                      marginTop: 24,
                      marginBottom: 24,
                      padding: 12,
                      borderWidth: 3,
                      borderColor: "black",
                      borderStyle: "solid",
                      borderRadius: 12,
                    }
              }
            >
              {hideStructure ? (
                <div />
              ) : (
                <div
                  style={{
                    position: "relative",
                    top: -32,
                    fontSize: 24,
                    backgroundColor: "white",
                    paddingLeft: 12,
                    width: 110,
                  }}
                >
                  Section {si + 1}
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <h3>
                  {index + 1}.{si + 1} - {section.name}
                </h3>
                {hideStructure ? null : (
                  <button
                    style={{
                      height: 30,
                      marginLeft: 12,
                      marginRight: 14,
                      cursor: "pointer",
                      border: "none",
                      backgroundColor: "white",
                      color: "blue",
                      marginBottom: 5,
                    }}
                    onClick={() => {
                      setModalType(ModalType.Section);
                      setPartOf(module._id);
                      setShowModal(true);
                      setModalData(section);
                    }}
                  >
                    Redigera
                  </button>
                )}
              </div>
              {section?.blocks?.map((block, bi) => (
                <div
                  key={block._id}
                  style={
                    hideStructure
                      ? {}
                      : {
                          marginTop: 12,
                          marginBottom: 12,
                          padding: 12,
                          borderWidth: 1,
                          borderColor: "blue",
                          borderStyle: "solid",
                          borderRadius: 12,
                        }
                  }
                >
                  {hideStructure ? null : (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                      }}
                    >
                      <button
                        style={{
                          marginLeft: 12,
                          cursor: "pointer",
                          border: "none",
                          backgroundColor: "white",
                          color: "blue",
                          marginBottom: 5,
                        }}
                        onClick={() => {
                          setModalType(ModalType.Block);
                          setPartOf(section._id);
                          setShowModal(true);
                          setModalData(block);
                          setModalBlockType("section");
                        }}
                      >
                        Redigera
                      </button>
                      <button
                        style={{
                          marginLeft: 12,
                          cursor: "pointer",
                          border: "none",
                          backgroundColor: "white",
                          color: "red",
                          marginBottom: 5,
                        }}
                        onClick={() => {
                          onDeleteBlockPress(block._id);
                        }}
                      >
                        Radera
                      </button>
                    </div>
                  )}
                  <Block
                    block={block}
                    prevBlock={bi > 0 ? section.blocks![bi - 1] : null}
                    nextBlock={
                      bi < section.blocks!.length - 1
                        ? section.blocks![bi + 1]
                        : null
                    }
                  />
                </div>
              ))}
              {hideStructure ? null : (
                <button
                  onClick={() => {
                    setModalType(ModalType.Block);
                    setPartOf(section._id);
                    setModalData(null);
                    setShowModal(true);
                    setModalBlockType("section");
                  }}
                >
                  + Lägg till block
                </button>
              )}
            </div>
          ))}
          {hideStructure ? null : (
            <button
              onClick={() => {
                setModalType(ModalType.Section);
                setPartOf(module._id);
                setModalData(null);
                setShowModal(true);
              }}
            >
              + Lägg till section
            </button>
          )}
          <hr />
          <h2>Activities</h2>
          {module.activities?.map((activity, ai) => (
            <div
              key={activity._id}
              style={
                hideStructure
                  ? {}
                  : {
                      marginTop: 12,
                      marginBottom: 12,
                      padding: 12,
                      borderWidth: 1,
                      borderColor: "blue",
                      borderStyle: "solid",
                      borderRadius: 12,
                    }
              }
            >
              {hideStructure ? null : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                  }}
                >
                  <button
                    style={{
                      marginLeft: 12,
                      cursor: "pointer",
                      border: "none",
                      backgroundColor: "white",
                      color: "blue",
                      marginBottom: 5,
                    }}
                    onClick={() => {
                      setModalType(ModalType.Activity);
                      setPartOf(module._id);
                      setShowModal(true);
                      setModalData(activity);
                    }}
                  >
                    Redigera
                  </button>
                </div>
              )}
              <h3>
                {index + 1}.{ai + 1} - {activity.name}
              </h3>
              <img
                src={activity.coverImagePath}
                style={{ width: "100%", height: "auto" }}
              />
              <h4>How?</h4>
              {activity.howBlocks?.map((block, bi) => (
                <div
                  key={block._id}
                  style={
                    hideStructure
                      ? {}
                      : {
                          marginTop: 12,
                          marginBottom: 12,
                          padding: 12,
                          borderWidth: 1,
                          borderColor: "blue",
                          borderStyle: "solid",
                          borderRadius: 12,
                        }
                  }
                >
                  {hideStructure ? null : (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                      }}
                    >
                      <button
                        style={{
                          marginLeft: 12,
                          cursor: "pointer",
                          border: "none",
                          backgroundColor: "white",
                          color: "blue",
                          marginBottom: 5,
                        }}
                        onClick={() => {
                          setModalType(ModalType.Block);
                          setPartOf(block._id);
                          setShowModal(true);
                          setModalData(block);
                          setModalBlockType("activity");
                          setBlockOf("how");
                        }}
                      >
                        Redigera
                      </button>
                      <button
                        style={{
                          marginLeft: 12,
                          cursor: "pointer",
                          border: "none",
                          backgroundColor: "white",
                          color: "red",
                          marginBottom: 5,
                        }}
                        onClick={() => {
                          onDeleteActvityBlockPress(block._id);
                        }}
                      >
                        Radera
                      </button>
                    </div>
                  )}
                  <Block
                    block={block}
                    prevBlock={bi > 0 ? activity.howBlocks![bi - 1] : null}
                    nextBlock={
                      bi < activity.howBlocks!.length - 1
                        ? activity.howBlocks![bi + 1]
                        : null
                    }
                  />
                </div>
              ))}
              {hideStructure ? null : (
                <button
                  onClick={() => {
                    setModalType(ModalType.Block);
                    setPartOf(activity._id);
                    setModalData(null);
                    setShowModal(true);
                    setModalBlockType("activity");
                    setBlockOf("how");
                  }}
                >
                  + Lägg till block
                </button>
              )}
              <h4>why?</h4>
              {activity.whyBlocks?.map((block, bi) => (
                <div
                  key={block._id}
                  style={
                    hideStructure
                      ? {}
                      : {
                          marginTop: 12,
                          marginBottom: 12,
                          padding: 12,
                          borderWidth: 1,
                          borderColor: "blue",
                          borderStyle: "solid",
                          borderRadius: 12,
                        }
                  }
                >
                  {hideStructure ? null : (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                      }}
                    >
                      <button
                        style={{
                          marginLeft: 12,
                          cursor: "pointer",
                          border: "none",
                          backgroundColor: "white",
                          color: "blue",
                          marginBottom: 5,
                        }}
                        onClick={() => {
                          setModalType(ModalType.Block);
                          setPartOf(block._id);
                          setShowModal(true);
                          setModalData(block);
                          setModalBlockType("activity");
                          setBlockOf("why");
                        }}
                      >
                        Redigera
                      </button>
                      <button
                        style={{
                          marginLeft: 12,
                          cursor: "pointer",
                          border: "none",
                          backgroundColor: "white",
                          color: "red",
                          marginBottom: 5,
                        }}
                        onClick={() => {
                          onDeleteActvityBlockPress(block._id);
                        }}
                      >
                        Radera
                      </button>
                    </div>
                  )}
                  <Block
                    block={block}
                    prevBlock={bi > 0 ? activity.howBlocks![bi - 1] : null}
                    nextBlock={
                      bi < activity.howBlocks!.length - 1
                        ? activity.howBlocks![bi + 1]
                        : null
                    }
                  />
                </div>
              ))}
              {hideStructure ? null : (
                <button
                  onClick={() => {
                    setModalType(ModalType.Block);
                    setPartOf(activity._id);
                    setModalData(null);
                    setShowModal(true);
                    setModalBlockType("activity");
                    setBlockOf("why");
                  }}
                >
                  + Lägg till block
                </button>
              )}
            </div>
          ))}
          {hideStructure ? null : (
            <button
              onClick={() => {
                setModalType(ModalType.Activity);
                setPartOf(module._id);
                setModalData(null);
                setShowModal(true);
              }}
            >
              + Lägg till activity
            </button>
          )}
        </div>
      ))}
      {hideStructure ? null : (
        <button
          onClick={() => {
            setModalType(ModalType.Module);
            setPartOf(course._id);
            setModalData(null);
            setShowModal(true);
          }}
        >
          + Lägg till module
        </button>
      )}
    </div>
  );
}

import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCourses } from "../../service/ecuApi/api";
import { SupportedLanguages } from "../user/userThunk";

export const fetchCourses = createAsyncThunk(
  "course/fetchFullCourseById",
  async (lang: SupportedLanguages, thunkAPI) => {
    const courseResponse = await getCourses(lang);
    const courses = courseResponse.data;
    return courses;
  }
);

import React, { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { fetchFullCourseById } from "../../store/course/courseThunk";
import {
  createCourseSection,
  updateCourseSection,
} from "../../service/ecuApi/api";
import { AxiosResponse } from "axios";

interface Props {
  closeModal?: () => void;
  courseId?: string;
  partOf?: string;
  data?: any;
}

export default function Section({ closeModal, courseId, partOf, data }: Props) {
  const [name, setName] = useState<string>("");

  const dispatch = useAppDispatch();
  const { language } = useAppSelector((state) => state.settings);

  useEffect(() => {
    if (data) {
      setName(data.name);
    }
  }, [data]);

  return (
    <div
      style={{
        marginTop: 12,
        marginLeft: 12,
        marginRight: 12,
      }}
    >
      <h1>Lägg till ny section</h1>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <label>Titel</label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          style={{ marginBottom: 12, height: 30 }}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <button
          onClick={() => {
            if (closeModal) {
              closeModal();
            }
          }}
        >
          Avbryt
        </button>
        <button
          onClick={async () => {
            if (courseId && partOf) {
              let query: AxiosResponse<any, any>;
              if (data && data._id) {
                query = await updateCourseSection({
                  id: data._id,
                  name,
                  moduleId: partOf,
                  lang: language,
                });
              } else {
                query = await createCourseSection({
                  name,
                  moduleId: partOf,
                });
              }
              if (query.status !== 200) {
                console.log(query);
                return;
              } else {
                if (courseId) {
                  dispatch(
                    fetchFullCourseById({
                      id: courseId,
                      lang: language,
                    })
                  );
                }
                if (closeModal) {
                  closeModal();
                }
              }
            }
          }}
        >
          Spara
        </button>
      </div>
    </div>
  );
}

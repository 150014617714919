import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getCourse,
  getCourseModules,
  getCourseSections,
  getCourseBlocks,
  getActivities,
  getActivityBlocks,
} from "../../service/ecuApi/api";

import { SupportedLanguages } from "../user/userThunk";

export interface CourseQueryParams {
  id: string;
  lang: SupportedLanguages;
}

export const fetchFullCourseById = createAsyncThunk(
  "course/fetchFullCourseById",
  async (queryParams: CourseQueryParams, thunkAPI) => {
    const courseResponse = await getCourse(queryParams.id, queryParams.lang);
    const course = courseResponse.data;
    console.log({ _id: course.courseModules });
    const modulesResponse = await getCourseModules(
      { _id: course.courseModules },
      queryParams.lang
    );
    const modulesData = modulesResponse.data;
    const modules = [];
    for (const module of modulesData) {
      const sectionsResponse = await getCourseSections(
        { _id: module.moduleSections },
        queryParams.lang
      );
      const sections = sectionsResponse.data;
      for (const section of sections) {
        const blocksResponse = await getCourseBlocks(
          { _id: section.blocks },
          queryParams.lang
        );
        section.blocks = blocksResponse.data;
      }
      module.sections = sections;

      const activitiesResponse = await getActivities(
        { _id: module.moduleActivities },
        queryParams.lang
      );

      const activities = activitiesResponse.data;
      for (const activity of activities) {
        const blocksResponseHow = await getActivityBlocks(
          { _id: activity.how },
          queryParams.lang
        );

        activity.howBlocks = blocksResponseHow.data;
        const blocksResponseWhy = await getActivityBlocks(
          { _id: activity.why },
          queryParams.lang
        );
        activity.whyBlocks = blocksResponseWhy.data;
      }
      module.activities = activities;

      modules.push(module);
    }
    return {
      ...course,
      modules,
    };
  }
);

export interface Course {
  _id?: string;
  title: string;
  description: string;
  goals: string;
}
